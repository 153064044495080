export function copyToClipboard(toCopy: any) {
    const el = document.createElement(`textarea`)
    el.value = toCopy
    el.setAttribute(`readonly`, ``)
    el.style.position = `absolute`
    el.style.left = `-9999px`
    document.body.appendChild(el)
    el.select()
    document.execCommand(`copy`)
    document.body.removeChild(el)
}

export function toKebabCase(str: any) {
    return str
        .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
        )
        .map((x: any) => x.toLowerCase())
        .join("-")
}
