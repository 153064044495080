import styled from "@emotion/styled"
import Highlight, { defaultProps } from "prism-react-renderer"
import React, { useState } from "react"
import Icons from "../../icons"
import { copyToClipboard } from "../../utils/Index"

interface CodePrismProps {
    children?: any
    className?: any
}

export default ({ children, className }: CodePrismProps) => {
    const language = className
        ? className.replace(/language-/, "")
        : "javascript"

    return (
        <Highlight {...defaultProps} code={children} language={language}>
            {({ style, tokens, getLineProps, getTokenProps }) => (
                <pre
                    className={className}
                    style={{
                        ...style,
                        padding: "20px",
                        position: "relative",
                        marginBottom: "0.2rem",
                    }}
                >
                    <Copy toCopy={children} />
                    {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line, key: i })}>
                            <span className="number-line">{i + 1}</span>
                            {line.map((token, key) => (
                                <span
                                    key={key}
                                    {...getTokenProps({ token, key })}
                                />
                            ))}
                        </div>
                    ))}
                </pre>
            )}
        </Highlight>
    )
}

function Copy({ toCopy }: any) {
    const [hasCopied, setHasCopied] = useState(false)

    function copyToClipboardOnClick() {
        if (hasCopied) {
            return
        }

        copyToClipboard(toCopy)
        setHasCopied(true)

        setTimeout(() => {
            setHasCopied(false)
        }, 2000)
    }

    return (
        <CopyButton
            name="copy"
            onClick={copyToClipboardOnClick}
            data-a11y="false"
        >
            {hasCopied ? (
                <>
                    <Icons.Copied fill="#6f7177" />
                </>
            ) : (
                <>
                    <Icons.Copy fill="#6f7177" />
                </>
            )}
        </CopyButton>
    )
}

const CopyButton = styled.button`
    position: absolute;
    right: 22px;
    top: 24px;
    padding: 8px 12px 7px;
    border-radius: 5px;
    background: none;
    border: none;
    transition: background 0.3s ease;
    &:hover {
        background: rgba(255, 255, 255, 0.07);
    }
    &[data-a11y='true']:focus::after {
        content: '';
        position: absolute;
        left: -2%;
        top: -2%;
        width: 104%;
        height: 104%;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.01);
    }
`
