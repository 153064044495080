import { graphql, Link } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

import { config } from "../../data"
import Layout from "../components/Layout"
import MDXRenderer from "../components/MDX"
import SEO from "../components/Seo"
// import "prismjs/themes/prism.css"
import "../styles/global.scss"
import { rhythm, scale } from "../utils/typography"

import profilePic from "../assets/avatar.jpg"

const { gitalk } = config

import Gitalk from "gitalk"
import "gitalk/dist/gitalk.css"

interface IProp {
    data?: any
    pageContext?: any
    location?: any
}

class BlogPostTemplate extends React.Component<IProp> {
    public componentDidMount() {
        const GitTalkInstance = new Gitalk({
            ...gitalk,
            title: this.props.data.mdx.frontmatter.title,
        })
        GitTalkInstance.render("gitalk-container")
    }
    public render() {
        const post = this.props.data.mdx
        const mdx = this.props.data.mdx
        const siteTitle = this.props.data.site.siteMetadata.title
        const siteDescription = post.excerpt
        const { previous, next } = this.props.pageContext

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={post.frontmatter.title} />
                <Helmet
                    htmlAttributes={{ lang: "en" }}
                    meta={[
                        { name: "description", content: siteDescription },
                        {
                            name: "viewport",
                            content:
                                "width=device-width, initial-scale=1, shrink-to-fit=no",
                        },
                    ]}
                    title={`${post.frontmatter.title} | ${siteTitle}`}
                />
                <div className="Headers">
                    <p
                        style={{
                            ...scale(-1 / 5),
                            display: "block",
                            marginBottom: rhythm(1),
                            marginTop: rhythm(5),
                            color: "grey",
                            fontSize: rhythm(0.6),
                        }}
                    >
                        {post.frontmatter.date} ——{" "}
                        <Link
                            to={"/categories/" + post.frontmatter.categories}
                            style={{
                                boxShadow: "none",
                                color: "grey",
                            }}
                        >
                            {post.frontmatter.categories}
                        </Link>
                        {/*<small>{post.parent.modifiedTime}修改</small>{' '}
                    <small>阅读时间 {post.timeToRead} 分钟</small>*/}
                    </p>
                    <h1 style={{ margin: 0 }}>{post.frontmatter.title}</h1>
                    <br />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "grey",
                            marginBottom: rhythm(1),
                        }}
                    >
                        <img
                            src={profilePic}
                            alt={`Ulyso`}
                            style={{
                                marginRight: rhythm(1 / 2),
                                marginBottom: 0,
                                width: rhythm(1.6),
                                height: rhythm(1.6),
                                borderRadius: "50%",
                            }}
                        />
                        <span>Ulyso</span>
                    </div>
                </div>

                <MDXRenderer content={mdx.body}></MDXRenderer>
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />
                <ul
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        listStyle: "none",
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
                <div id="gitalk-container" />
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        mdx(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            frontmatter {
                title
                date(formatString: "MMMM DD YYYY")
                categories
            }
            parent {
                ... on File {
                    modifiedTime(fromNow: true, locale: "zh-CN")
                }
            }
            body
            timeToRead
        }
    }
`
