import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { toKebabCase } from "../utils/Index"
import Blockquote from "./Blockquote"
import CodePrism from "./Code/Code.Prism"
import Headings from "./Headings"
import HorizontalRule from "./HorizontalRule"
import OrderedList from "./OrderedList"
import Prism from "./Prism"

const components = {
    pre: (props: any) => <pre {...props} />,
    code: CodePrism,
    img: (props: any) => <img {...props} />,
    a: (props: any) => <a {...props} />,
    blockquote: Blockquote,
    h1: Headings.h1,
    h2: Headings.h2,
    h3: Headings.h3,
    h4: Headings.h4,
    h5: Headings.h5,
    h6: Headings.h6,
    hr: HorizontalRule,
    ul: (props: any) => <ul {...props} />,
    ol: OrderedList,
    p: (props: any) => <p {...props} />,
    table: (props: any) => <table {...props} />,
    thead: (props: any) => <thead {...props} />,
    th: (props: any) => <th {...props} />,
    td: (props: any) => <td {...props} />,
}

function MDX({ content, children, ...props }: any) {
    return (
        <MDXProvider components={components}>
            <MDXBody>
                <MDXRenderer {...props}>{content}</MDXRenderer>
                {children}
            </MDXBody>
        </MDXProvider>
    )
}

export default MDX

const PrismCSS = (p: any) => css`
  .prism-code {
    overflow: overlay;
    width: 100%;
    max-width: 63rem;
    margin: 0 auto;
    padding: 32px;
    font-size: 13px;
    margin: 15px auto 50px;
    border-radius: 5px;
    font-family: "Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace;
    background: #292c34;
    .token-line {
      border-left: 3px solid transparent;
      ${Object.keys(Prism)
          .map((key) => {
              return `.${toKebabCase(key)}{color:${Prism[key]};}`
          })
          .reduce((curr, next) => curr + next, ``)};
      & > span {
      }
    }
    .number-line {
      display: inline-block;
      width: 32px;
      user-select: none;
      opacity: 0.3;
      color: #dcd9e6;
    }
    .token-line.highlight-line {
      margin: 0 -32px;
      padding: 0 32px;
      /* background: ${p.theme.colors.prism.highlight};
      border-left: 3px solid ${p.theme.colors.prism.highlightBorder}; */
    }
    .operator + .maybe-class-name {
      color: #ffcf74 !important;
    }
    .plain ~ .operator {
      color: #5fa8aa !important;
    }
  }
`

const MDXBody = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${PrismCSS}
`
