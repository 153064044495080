import styled from "@emotion/styled"

const Blockquote = styled.blockquote`
    position: relative;
    display: block;
    margin: 37px 0;
    border-left: 2px solid #fd281a;
    font-style: normal;
    text-align: left;
    color: #8e8787;
    font-size: 18px;
    line-height: 160%;
    padding: 0 12px;
    &::before {
        content: '';
        height: 8.4px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAARCAYAAADHeGwwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFBSURBVHgB7ZQ/UoNAFMa/t6BhYiF2ig2d2HGE5AYeIZwgeoLEk2S8QTxBcgNbxopGTReqmCjsk2UmCMifJDPOWPgVDLvzvd/7WN4s8Mui8gbbpgmjO2SGS5uVR0EYtkE+nfMemG4E0anmv3q1Dfj6YihBYzBMtZaQ/SN/Ma8Dp2E6JyNJfLvdSxoUmHpmdqyRZIyxh7jTnTGx2+QR6hE5lwOJHJwQJuv7xvRJICbk4BwIyEHZl35OfGU9ITMrI/fJXwS1cNs2pfGxzCCEKb1X/y+hzMglkWCvCZ4GOt70CilZ3tUNg4iMdeEMm47lu0qY21cGz5sC6eWN2LEmP3igBPLyUAUgJreqhiU/6s9vU1IzLCBmaFF+ZNVQEHjSViPWqzOBHUXQbOypyDDcnRscqv8Gf6ABx1rrdaykiWiJA/QFHEl+W66g4YEAAAAASUVORK5CYII=)
            no-repeat;
        display: block;
        background-size: contain;
        line-height: 160%;
        margin-left: 4px;
        margin-bottom: 7.2px;
    }
    & > p {
        font-size: 18px;
        line-height: 160%;
    }
`

export default Blockquote
