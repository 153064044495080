import { css } from "@emotion/core"
import styled from "@emotion/styled"

const commonStyles = (p: any) => css`
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, PingFang-SC-Regular,
        Hiragino Sans GB, Microsoft Yahei, Arial, sans-serif;
    margin-top: 0.2rem;
`

const h1 = styled.h1`
    font-size: 52px;
    line-height: 1.15;
    ${commonStyles}
`
const h2 = styled.h2`
    font-size: 32px;
    line-height: 1.333;
    ${commonStyles};
`
const h3 = styled.h3`
    font-size: 24px;
    line-height: 1.45;
    ${commonStyles};
`
const h4 = styled.h4`
    font-size: 18px;
    line-height: 1.45;
    ${commonStyles};
`
const h5 = styled.h5`
    font-size: 18px;
    line-height: 1.45;
    ${commonStyles};
`
const h6 = styled.h6`
    font-size: 16px;
    line-height: 1.45;
    ${commonStyles};
`

export default {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
}
